import config from '../config'
class SuperFetch {
    fetchObj: any;
    methods: Array<string>;
    constructor() {
        this.fetchObj = {};
        this.methods = ['get', 'post', 'put', 'patch', 'delete'];
        this.bindMethod();
    }

    bindMethod() {
        this.methods.forEach(method => {
            this.fetchObj[method] = this.fetch.bind(this, method);
        });
    }

    async fetch(method: string, path: string, bodyObj?: any) {
        try {
            const response = await fetch(`${config.endpoint}${path}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',

                },
                method,

                // referrer: "https://www.glivestreaming.com",
                body: method !== 'get' ? JSON.stringify(bodyObj) : undefined,
            });
            console.log(response)
            if (!response.ok) {
                if (response.status === 501) {
                    console.error('501 Not Implemented: The server does not support the functionality required to fulfill the request.');
                } else if (response.status === 401) {
                    console.error('401 Unauthorized: Authentication is required and has failed or has not yet been provided.');
                } else if (response.status === 403) {
                    console.error('403 Forbidden: The server understood the request, but refuses to authorize it.');
                } else {
                    console.error(`HTTP error! Status: ${response.status}`);
                }
                const errorResponse = await response.text();
                throw new Error(`Error ${response.status}: ${errorResponse}`);
            }

            const json = await response.json();
            return json;
        } catch (error) {
            throw error;
        }
    }

    get instance() {
        return this.fetchObj;
    }
}

export default new SuperFetch().instance;
