import SuperFetch from "../../helper/superFetch"

const apiuser = "doball" //doball duballclub
const key = "D8uc17xAz" //D8uc17xAz 8305caD71F
//get this ip funtions
// const ip = "68.178.151.162"
const brand = "doball"
const ch = "8400"
const lang = "en"
const fs = "1"
// const uid = "3979344919"
class services {
    getRandomMatch = async (props: any) => {
        //     action =  geth5link , getmatch
        //     apiuser =  doball
        //     key =  D8uc17xAz
        //     ip =  68.178.151.162
        //     brand =  doball
        //     ch =  8400
        //     lang =  en
        //     fs =  1
        //     uid =  3979344919
        // const { uid, ip } = props
        const action = 'geth5link'
        // const url = `/api.php?action=${action}&apiuser=${apiuser}&key=${key}&ip=${ip}&brand=${brand}&ch=${ch}&lang=${lang}&fs=${fs}&uid=${uid}`;
        const url = `/get-random-match-data`;
        return await SuperFetch.get(url,
            {}).then((response: any) => {
                console.log(response)

                return response;
            });
    };
    getMatchList = async (props: any) => {
        //     action =  geth5link , getmatch
        //     apiuser =  doball
        //     key =  D8uc17xAz
        //     ip =  68.178.151.162
        //     brand =  doball
        //     ch =  8400
        //     lang =  en
        //     fs =  1
        //     uid =  3979344919
        const { sportstype } = props
        const apiuser2 = "duballclub" //doball
        const key2 = "8305caD71F" //D8uc17xAz

        const action = 'getmatch'
        // const url = `/api.php?action=${action}&apiuser=${apiuser2}&key=${key2}&sportstype=${sportstype}&format=JSON`;
        const url = `/get-match-data?sportstype=FOOTBALL`;

        return await SuperFetch.get(url,
            {}).then((response: any) => {
                return response;
            });
    };
    getSelectLive = async (props: any) => {
        //     action =  geth5link , getmatch
        //     apiuser =  doball
        //     key =  D8uc17xAz
        //     ip =  68.178.151.162
        //     brand =  doball
        //     ch =  8400
        //     lang =  en
        //     fs =  1
        //     uid =  3979344919
        const { uid, ip, matchID } = props
        const action = 'geth5link'
        const url = `https://doball.live/wp-json/custom/v1/get-match-data?sportstype=FOOTBALL`;
        return await SuperFetch.get(url,
            {}).then((response: any) => {
                return response;
            });
    };
}

export default new services();
