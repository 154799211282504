import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd';
import th from 'antd/locale/th_TH';
import { ScreenSizeProvider } from './services/ScreenSizeContext';
import { RootRoute } from './routes/Index';
import store from './redux/Store';
export class App extends Component {

  componentDidMount(): void {
  }

  render() {
    return (
      <Provider store={store}>
        <ScreenSizeProvider>
          <ConfigProvider locale={th}>
            {/* <AppErrorBoundary> */}
            <RootRoute></RootRoute>

            {/* </AppErrorBoundary>, */}
          </ConfigProvider>
        </ScreenSizeProvider>
      </Provider>
    )
  }
}

export default App;