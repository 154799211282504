// sportsData.ts
export const sportsData: { [key: string]: string } = {
    FOOTBALL: 'ฟุตบอล',
    BASKETBALL: 'บาสเกตบอล',
    TENNIS: 'เทนนิส',
    VOLLEYBALL: 'วอลเลย์บอล',
    BOXING: 'มวย',
    BADMINTON: 'แบดมินตัน',
    MMA: 'MMA',
    TABLETENNIS: 'ปิงปอง',
    CRICKET: 'คริกเก็ต',
    ESPORTS: 'e-สปอร์ตเกมส์',
    EFOOTBALL: 'e-สปอร์ตฟุตบอล',
    EBASKETBALL: 'e-สปอร์ตบาสเกตบอล',
    GOLF: 'กอล์ฟ',
    HOCKEY: 'ฮอคกี้',
    ICEHOCKEY: 'ไอซ์ฮอคกี้',
    SNOOKER: 'สนุ๊กเกอร์',
    BASEBALL: 'เบสบอล'
};
