import {
    UnorderedListOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    MenuOutlined,
    HomeOutlined,
    HistoryOutlined,
    SettingOutlined
} from '@ant-design/icons'
import { Layout, Image, Menu, Drawer, Row, Col, Button } from "antd"
import React, { Component, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AppMenu from './AppMenu'
import { themeColor } from './../assets/theamColor';
import { connect } from 'react-redux'
import { RoutesName } from '../routes/routeName.constants'
import logo from '../assets/image/logo.png'
import { useIsMobile } from '../services/ScreenSizeContext'
const { Header, Content, Footer, Sider } = Layout;


interface Props {

    children: React.ReactNode
}
const AppLayout: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const isMobile = useIsMobile();
    useEffect(() => {
        // ตั้งค่า initial state จาก prop ที่ได้รับ
    }, [])
    const layoutStyle: React.CSSProperties = isMobile ? {
        height: "100%",
    } : {
        height: "100%", minHeight: "100dvh"
    }
    return (
        <Layout style={layoutStyle}>
            <Header style={{ position: 'fixed', top: 0, zIndex: 2, width: '100vw', backgroundColor: themeColor.main2, }}>
                <AppMenu ></AppMenu>
            </Header>
            <Content style={{
                backgroundColor: themeColor.main,
                // height: "100vh",
                marginTop: 64
            }}>
                {children}
            </Content>

            <Footer style={{
                backgroundColor: themeColor.main2,
                color: "white",

            }}>
                <center>
                    <Image preview={false} src={logo} style={{ height: 60, minWidth: 250 }}></Image>
                </center>
            </Footer>


        </Layout>
    )
};

const mapState = ({ }: any) => {
    return {

    };
};

export default connect(mapState, {})(AppLayout);



