import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

type ScreenSizeContextType = boolean | undefined;

const ScreenSizeContext = createContext<ScreenSizeContextType>(undefined);

interface ScreenSizeProviderProps {
    children: ReactNode;
}
// สร้าง Provider Component
export const ScreenSizeProvider: React.FC<ScreenSizeProviderProps> = ({ children }) => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <ScreenSizeContext.Provider value={isMobile}>{children}</ScreenSizeContext.Provider>;
};

// Custom hook สำหรับการเข้าถึงค่าของ isMobile
export const useIsMobile = (): boolean => {
    const context = useContext(ScreenSizeContext);
    if (context === undefined) {
        throw new Error('useIsMobile must be used within a ScreenSizeProvider');
    }
    return context;
};
