import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button, Card, Col, Modal, Row, Form, Input, Image, Menu, MenuProps, Drawer } from 'antd';
import { HomeOutlined, UserOutlined, SettingOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
import logo from '../assets/image/logo.png'
import { themeColor } from '../assets/theamColor';
import { useIsMobile } from '../services/ScreenSizeContext';
import marinaLogo from '../assets/marinaLogo.png'
import line_icon from '../assets/line_icon.png'
import LineContact from '../components/LineContact';
interface Props {

}
type MenuItem = Required<MenuProps>['items'][number];
const AppMenu: React.FC<Props> = ({ }: Props) => {
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const location = useLocation();
    const [openMenu, setOpenMenu] = useState(false);
    const isMobile = useIsMobile()
    function getItem(
        key: React.Key,
        name: string,
        label: React.ReactNode,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            name,
            children,
            label,
            type,
        } as MenuItem;
    }
    let items: MenuProps['items'] = [
        // getItem(1, `https://play.marinabet.net/register?f=MDg3NzYwOTk5OA==`, <Image src={marinaLogo}  style={{ height: 56 }}></Image>, null),
    ]
    const [form] = Form.useForm();
    useEffect(() => {

    }, []);
    let i = 1;
    const pathname = location.pathname;
    items.forEach((element: any) => {
        if (element.children) {
            if (element.name === pathname) {
                i = element.key
            } else {
                element.children.forEach((element2: any) => {
                    if (element2.name === pathname) {
                        i = element2.key
                    }
                })
            }
        } else {
            if (element.name === pathname) {
                i = element.key
            }
        }

    });



    return (

        <div >
            <Row wrap={false}>
                <Col span={8} style={{ justifyContent: "flex-end", height: 56 }}>
                    <Image preview={false} src={logo} style={{ height: 60, minWidth: 250 }}></Image>
                </Col>
                <Col span={8} offset={8} style={{ display: "flex", justifyContent: "flex-end", height: 56 }}>
                    {/* <div>
                        <a href={`https://play.marinabet.net/register?f=MDg3NzYwOTk5OA==`} target="_blank" rel="noopener noreferrer">
                            <Image src={marinaLogo} preview={false} style={{ height: 56 }}></Image></a>
                    </div> */}
                    < a style={{
                        flexDirection: "row", backgroundColor: "#00c854", display: "flex",
                        alignItems: "center",
                        borderRadius: 5, height: isMobile ? "56px" : "64px", width: isMobile ? "56px" : "200px"
                    }}
                        href={`https://page.line.me/239xecgo`} target="_blank" rel="noopener noreferrer"
                    >
                        <Image src={line_icon} preview={false} style={{ height: 56, width: 56 }}></Image>
                        {!isMobile && <span style={{ color: "white", }}>ติดตามตารางบอลที่นี่</span>}
                    </a>
                </Col>
                <>

                    {/* {isMobile ?
                        <Col flex="auto" style={{ justifyContent: "flex-end" }}>

                            <div style={{ marginTop: 6 }}>
                                <MenuOutlined style={{ color: 'white', fontSize: 30 }}
                                    onClick={() => {
                                        setOpenMenu(true)
                                    }}
                                ></MenuOutlined>
                            </div>

                            <Drawer
                                open={openMenu}
                                onClose={() => {
                                    setOpenMenu(false)
                                }}
                                style={{ backgroundColor: themeColor.main }}
                                placement="right"
                                closable={true}
                                closeIcon={<CloseOutlined style={{ color: 'white' }} />}
                            >
                                <Menu
                                    theme="dark"
                                    style={{ backgroundColor: themeColor.main }}
                                    mode={openMenu ? "inline" : "horizontal"}
                                    defaultSelectedKeys={['1']}
                                    selectedKeys={[i.toString()]}
                                    items={items}

                                    onClick={() => {
                                        setOpenMenu(false)
                                    }}
                                />
                            </Drawer>
                        </Col>
                        :
                        <Col flex="auto" style={{ marginLeft: 5 }}>
                            <Menu
                                theme="dark"
                                style={{ backgroundColor: themeColor.main2, justifyContent: "end", height: 61.5 }}
                                mode="horizontal"
                                defaultSelectedKeys={['1']}
                                selectedKeys={[i.toString()]}
                                items={items}
                            >
                            </Menu>
                        </Col>

                    } */}
                </>
            </Row>

        </div >



    );

}
const mapState = ({ }: any) => {
    return {

    };
};
export default connect(mapState, {})(AppMenu)

