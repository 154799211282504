// src/redux/sagas/exampleSaga.ts
import {
    takeEvery,
    all,
    delay,
    race,
    fork,
    call,
    put,
} from "redux-saga/effects";
import { message } from "antd";
import actions from "./actions";
import services from "./services";

function* getRandomMatchWorker() {
    yield takeEvery(actions.GET_CURRENT_LIVE, function* ({ props }: any) {

        let { res, timeout }: { res: any, timeout: any } = yield race({
            res: call(services.getRandomMatch, props),
            timeout: delay(15000),
        });
        console.log(res)
        if (res.status === "OK") {
            yield put({ type: actions.GET_CURRENT_LIVE_SUCCESS, data: res });
        } else {
            // console.log("error call api", res)
            // alert(res.message);
        }

    });
}

function* getMatchListWorker() {
    yield takeEvery(actions.GET_MATCH_LIST, function* ({ props }: any) {
        try {
            let { res, timeout }: { res: any, timeout: any } = yield race({
                res: call(services.getMatchList, props),
                timeout: delay(15000),
            });
            if (res.status === "OK") {
                yield put({ type: actions.GET_MATCH_LIST_SUCCESS, data: res });
            } else {
                console.log("error call api", res)
                // alert(res.message);
            }
        } catch (error: any) {
            yield put({ type: actions.GET_MATCH_LIST_ERROR });
            console.log("error call api", error)
            // alert(error.message);
        }
    });
}

function* getSelectMatchWorker() {
    yield takeEvery(actions.GET_LIVE, function* ({ props }: any) {
        try {
            let { res, timeout }: { res: any, timeout: any } = yield race({
                res: call(services.getSelectLive, props),
                timeout: delay(15000),
            });
            if (res.status === "OK") {
                yield put({ type: actions.GET_LIVE_SUCCESS, data: res });
            } else {
                console.log("error call api", res)
                // alert(res.message);
            }
        } catch (error: any) {
            yield put({ type: actions.GET_LIVE_ERROR });
            console.log("error call api", error)
            // alert(error.message);
        }
    });
}


// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
    yield all([
        fork(getRandomMatchWorker),
        fork(getMatchListWorker),
        fork(getSelectMatchWorker),
    ]);
}
